const MiniNav = (pros) => {
  console.log("open", pros)
  return (
    <div className={`${!pros.open ? "hidden" : "flex"} MuiBox-root css-173q3av `}>
      {/* ${!pros.open ? "hidden" : ""} */}
  <div className="MuiBox-root css-998l1r">
    <a href="/">
      <div className="MuiBox-root css-5x7cxk">
        <img
          alt="_"
          srcSet="/_next/static/media/icon.7a2fa43a.svg 1x, /_next/static/media/icon.7a2fa43a.svg 2x"
          src="/_next/static/media/icon.7a2fa43a.svg"
          width={32}
          height={37}
          decoding="async"
          data-nimg={1}
          loading="lazy"
          style={{ color: "transparent" }}
        />
        <h5 style={{ lineHeight: 1, whiteSpace: "nowrap" }}>Sakai Vault</h5>
      </div>
    </a>
    <div className="MuiBox-root css-1rqcahh">
      <a href="#tools" style={{ cursor: "pointer" }}>
        <p>Tools</p>
      </a>
      <a href="#tokenomics" style={{ cursor: "pointer" }}>
        <p>Tokenomics</p>
      </a>
      <a href="#roadmap" style={{ cursor: "pointer" }}>
        <p>Roadmap</p>
      </a>
      <a href="#ai-bot" style={{ cursor: "pointer" }}>
        <p>Prompt AI</p>
      </a>
      <a
        href="https://bridge.sakaivault.io"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Bridge</p>
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowOutwardRoundedIcon"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z" />
        </svg>
      </a>
      <a
        href="https://dex.sakaivault.io"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Swap</p>
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowOutwardRoundedIcon"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z" />
        </svg>
      </a>
      <a
        href="https://perpetual.sakaivault.io"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Perpetual</p>
        <svg
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          focusable="false"
          aria-hidden="true"
          viewBox="0 0 24 24"
          data-testid="ArrowOutwardRoundedIcon"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z" />
        </svg>
      </a>
    </div>
    {/* <a href="https://docs.sakaivault.io" style={{ display: "flex" }}>
      <p>Docs</p>
      <svg
        className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
        focusable="false"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="ArrowOutwardRoundedIcon"
      >
        <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z" />
      </svg>
    </a> */}
    {/* <div>
      <a href="https://app.sakaivault.io" style={{ display: "flex" }}>
        <div className="MuiBox-root css-19uhrur">Connect Wallet</div>{" "}
      </a>
    </div> */}
  </div>
</div>
  )
}

export default MiniNav