import MetamaskLogin from "./MetamaskLogin";
import MiniNav from "./Mininav";
import React from "react";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  return (
<>
<div className="MuiBox-root css-184s7oi">
  <a  href="/">
    <div className="MuiBox-root css-5x7cxk">
      <img
        alt="_"
        data-nimg={1}
        decoding="async"
        height={37}
        loading="lazy"
        src="/_next/static/media/icon.7a2fa43a.svg"
        srcSet="
                  /_next/static/media/icon.7a2fa43a.svg 1x,
                  /_next/static/media/icon.7a2fa43a.svg 2x
                "
        style={{ color: "transparent" }}
        width={32}
      />
      <h5 style={{ lineHeight: 1, whiteSpace: "nowrap" }}>Sakai Vault</h5>
    </div>
  </a>
  <div className="MuiBox-root css-15dixou">
    <div className="MuiBox-root css-1rqcahh">
      <a href="#tools" style={{ cursor: "pointer" }}>
        <p>Tools</p>
      </a>
      <a href="#tokenomics" style={{ cursor: "pointer" }}>
        <p>Tokenomics</p>
      </a>
      <a href="#roadmap" style={{ cursor: "pointer" }}>
        <p>Roadmap</p>
      </a>
      <a  href="#ai-bot" style={{ cursor: "pointer" }}>
        <p>Prompt AI</p>
      </a>
      <a
         href="https://bridge.sakaivault.io/"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Bridge</p>
        <svg
          aria-hidden="true"
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          data-testid="ArrowOutwardRoundedIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z"></path>
        </svg>
      </a>
      <a
         href="https://dex.sakaivault.io"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Swap</p>
        <svg
          aria-hidden="true"
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          data-testid="ArrowOutwardRoundedIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z"></path>
        </svg>
      </a>
      <a
       href="https://perpetual.sakaivault.io"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <p>Perpetual</p>
        <svg
          aria-hidden="true"
          className="MuiSvgIcon-root MuiSvgIcon-fontSize10px css-jpbqk9"
          data-testid="ArrowOutwardRoundedIcon"
          focusable="false"
          viewBox="0 0 24 24"
        >
          <path d="M6 7c0 .55.45 1 1 1h7.59l-8.88 8.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L16 9.41V17c0 .55.45 1 1 1s1-.45 1-1V7c0-.55-.45-1-1-1H7c-.55 0-1 .45-1 1z"></path>
        </svg>
      </a>
    </div>
  </div>
  <div className="MuiBox-root css-130o75g">
    <a  href="/" aria-label="Change Theme" className="hidden md:flex">
      <div className="MuiBox-root css-3azk8w">
        <svg
          fill="currentColor"
          height={16}
          stroke="currentColor"
          strokeWidth={0}
          viewBox="0 0 512 512"
          width={16}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M256 160c-52.9 0-96 43.1-96 96s43.1 96 96 96 96-43.1 96-96-43.1-96-96-96zm246.4 80.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.4-94.8c-6.4-12.8-24.6-12.8-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4c-12.8 6.4-12.8 24.6 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.4-33.5 47.3 94.7c6.4 12.8 24.6 12.8 31 0l47.3-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3c13-6.5 13-24.7.2-31.1zm-155.9 106c-49.9 49.9-131.1 49.9-181 0-49.9-49.9-49.9-131.1 0-181 49.9-49.9 131.1-49.9 181 0 49.9 49.9 49.9 131.1 0 181z"></path>
        </svg>
      </div>
    </a>
    <div className="MuiBox-root css-xbxyk2 flex">
      <a
        href="https://docs.sakaivault.io/"
        className="hidden md:block"
      >
        <div className="MuiBox-root css-1r3yjtt">
          <p>Docs</p>
        </div>
      </a>
      <div>
        {/* <a href="https://app.sakaivault.io" style={{ display: "flex" }} onClick={(e) => e.preventDefault()}> */}
          {/* <div className="MuiBox-root css-19uhrur">Connect Wallet</div> */}
          <MetamaskLogin />
        {/* </a> */}
      </div>
    </div>
    <div className="MuiBox-root css-1b7nu80" onClick={() => setOpen(!open)}>
      <div className="MuiBox-root css-b2qbtr">
        <div className="MuiBox-root css-3azk8w">
          <svg
            aria-hidden="true"
            className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
            data-testid="MenuIcon"
            focusable="false"
            size={16}
            viewBox="0 0 24 24"
          >
            {
              open ? (
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" className="text-emerald-200"></path>
              ) : (
                <path d="M4 18h16v-2H4v2zm0-5h16v-2H4v2zm0-7v2h16V6H4z"></path>
              )
            }
          </svg>
        </div>
      </div>
    </div>
  </div>
</div>
<MiniNav open={open}/>
</>

  );
}

export default Header;